import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RootState } from '../store'
import { EventHandler } from 'react'


export interface User {
  id: number,
  username: string,
}

export interface UserResponse {
  user: User,
  token: string,
}

export interface LoginRequest {
  username: string,
  password: string,
}

export interface Character {
  id: number,
  user: User,
  name: string,
  char_class: string,
  alignment: string,
  max_health: number,
  max_special: number,
  max_accuracy: number,
  max_strength: number,
  max_block: number,
  max_evade: number,
  max_speed: number,
  is_mortal: boolean,
  is_alive: boolean,
  created_at: string,
  last_modified_at: string,
  abilities: Ability[]
}

export interface BattleCharacter {
  id: number,
  can_battle: boolean,
  current_health: number,
  current_special: number,
  current_accuracy: number,
  current_strength: number,
  current_block: number,
  current_evade: number,
  current_speed: number,
  invite_status: string,
  battle: number,
  character: Character,
  team: string,
  created_at: string,
  last_modified_at: string,
}

export interface Battle {
  id: number,
  status: string,
  result: string,
  created_at: string,
  active_character: Character | null,
  last_modified_at: string,
  battle_characters: BattleCharacter[]
  battle_turns: BattleTurn[]
  created_by: number
}

export interface Ability {
  id: number,
  name: string,
  multi_target: boolean
}

export interface BattleActionSlot {
  id: number,
  no: number,
  status: string,
  battle_turn: number,
  battle_character: number,
}

export interface BattleTurn {
  id: number,
  no: number,
  is_ended: boolean,
  battle_action_slots: BattleActionSlot[]
}

export interface ActionRequest {
  battle_action_targets: {
      battle_character: number;
  }[];
  ability: number;
  battle_action_slot: number;
}

export interface ActionResponse {
  id: number,
  battle_action_targets: {
      battle_character: number;
  }[],
  ability: number,
  battle_action_slot: number,
}

export interface CommandRequest {
  command: string,
  battle_character: number
}

export interface CommandResponse {
  id: number,
  command: string,
  battle_character: number,
  created_by: number
}


export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_HOST,
    
    prepareHeaders: (headers, { getState }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = (getState() as RootState).game.token
      if (token) {
        headers.set('authorization', `Token ${token}`)
      }
      return headers
    },
  }),
  tagTypes: ['Battles'], // Define the tag type
  endpoints: (builder) => ({
    login: builder.mutation<UserResponse, LoginRequest>({
      query: (credentials) => ({
        url: 'login/',
        method: 'POST',
        body: credentials,
      }),
    }),
    getBattles: builder.query<Battle[], void>({
      query: (credentials) => ({
        url: 'battles/',
        method: 'GET',
      }),
      providesTags: ['Battles'], // Provide the tag when fetching battles
    }),
    getCharacters: builder.query<Character[], void>({
      query: (credentials) => ({
        url: 'characters/',
        method: 'GET',
      }),
    }),
    protected: builder.mutation<{ message: string }, void>({
      query: () => 'protected',
    }),
    postAction: builder.mutation<any, ActionRequest>({
      query: (action) => ({
        url: 'actions/',
        method: 'POST',
        body: action,
      }),
      invalidatesTags: ['Battles'], // Invalidate the tag to refresh battles
    }),
    postCommand: builder.mutation<any, CommandRequest>({
      query: (command) => ({
        url: 'commands/',
        method: 'POST',
        body: command,
      }),
    }),
  }),
})

export const {
  useLoginMutation, 
  useProtectedMutation, 
  useGetBattlesQuery,
  useGetCharactersQuery,
  usePostActionMutation,
  usePostCommandMutation
} = api
