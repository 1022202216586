import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'
import { User } from '../../app/services/game'

export interface GameState {
  user: User | null,
  token: string| null,
  activeScreen: string,
  activeBattle: number | null,
}

const initialState: GameState = {
  user: null,
  token: null,
  activeScreen: 'Battles',
  activeBattle: null,
}

export const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    setCredentials: (
      state,
      {
        payload: { user, token },
      }: PayloadAction<{ user: User; token: string }>,
    ) => {
      state.user = user
      state.token = token
    },
    setActiveScreen: (
      state,
      { payload: { screen } }: PayloadAction<{ screen: string }>
    ) => {
      state.activeScreen = screen
    },
    setActiveBattle: (
      state,
      { payload: { battle } }: PayloadAction<{ battle: number}>
    ) => {
      state.activeBattle = battle
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setCredentials,
  setActiveScreen,
  setActiveBattle
} = gameSlice.actions

export default gameSlice.reducer


export const selectUser = (state: RootState) => state.game.user
export const selectActiveScreen = (state: RootState) => state.game.activeScreen
export const selectActiveBattle = (state: RootState) => state.game.activeBattle