import * as React from 'react';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import { Battle, BattleActionSlot, BattleCharacter, BattleTurn, Character, User } from '../../app/services/game';
import { useGetCharactersQuery } from '../../app/services/game';
import { setActiveBattle, selectUser, setActiveScreen } from './gameSlice';
import { useTheme } from '@mui/material/styles';
import { BatteryCharging20 } from '@mui/icons-material';
import { getActiveCharacter, getCurrentSlot, getCurrentTurn } from './BattleUtils';


interface IProps {
  battle: Battle
  battleId: number
  battleStatus: string
  battleCharacters: BattleCharacter[]
  battleActiveChar: Character | null
  battleTurns: BattleTurn[] | null
}



export default function BattleCard(props:IProps) {
  const theme = useTheme();
  const {
    data: charactersData,
    isLoading: charactersLoading,
    isError: charactersError,
    error: charactersErrorData
  } = useGetCharactersQuery();

  const dispatch = useDispatch()

  const handleBattleClick = (battleId:number) => {
    console.log(battleId)
    dispatch(setActiveScreen({screen: 'BattleDetail'}))
    dispatch(setActiveBattle({battle: battleId}))
  }


  const generateBattleStatusLine = (activeChar:Character | null) => {
    if (!loggedInUser || !activeChar){
      return ('')
    }
    if(activeChar.user === null){
      return(`Waiting for ${activeChar.name}...`)
    }
    if(activeChar.user.id === loggedInUser.id){
      return("It's Your Turn!")
    } else {
      return(`Waiting for ${activeChar.name}...`)
    }
  }

  const loggedInUser = useSelector(selectUser);

  const currentTurn = props.battleTurns?getCurrentTurn(props.battle):null 

  const currentTurnNo = currentTurn && currentTurn.no ? currentTurn.no.toString() : '-1';

  const currentSlot = getCurrentSlot(props.battle)

  const activeCharacter = getActiveCharacter(props.battle)

  const currentSlotNo = currentSlot ? currentSlot.no : '-1'

  const battleStatusLine = generateBattleStatusLine(activeCharacter)



  return (
    <Box sx={{ width: 325, m:1 }}>
      <Card 
        variant="outlined"
        sx={{
          '&:hover': {
            outline: '2px solid', // Change color as per your preference
          },
        }}
      >
         
        <CardContent
          style={{ cursor: 'pointer' }}
          onClick={() => handleBattleClick(props.battleId)}
        >
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {
              props.battleStatus === 'Matching'
              ? 'Matching' 
              : props.battleStatus === 'Complete'
                ? 'Complete'
              : props.battleStatus === 'Abandoned'
                ? 'Abandoned'
              : props.battleStatus === 'Cancelled'
                ? 'Cancelled' 
              : props.battleStatus === 'Active'
                ? `Turn ${currentTurnNo}.${currentSlotNo}` 
              : ''
            }
          </Typography>
          <Typography 
            variant="h5" 
            component="div" 
            onClick={() => handleBattleClick(props.battleId)}>
            Battle #{props.battleId}
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            {
              props.battleStatus === 'Matching'
              ? 'Waiting for battle to start...' 
              : props.battleStatus === 'Complete'
                ? 'Victory!'
              : props.battleStatus === 'Abandoned'
                ? 'No Contest.'
              : props.battleStatus === 'Cancelled'
                ? 'Battle Cancelled' 
              : props.battleStatus === 'Active'
                ? generateBattleStatusLine(
                    activeCharacter
                  )
              : ''
            }
            {}
          </Typography>
          <Box sx={{mt:1, flexWrap:'wrap', maxWidth: '100%'}} >
            {props.battleCharacters.map(char => {
                return (
                  <Chip
                    key={char.character.id}
                    label={char.character.name || ''}
                    size="small"
                    variant="filled"
                    sx={{ m: 0.25, backgroundColor: char.team === 'Blue' ? theme.palette.primary.dark : theme.palette.secondary.dark }}

                  />
                );
              })}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}