import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import BattleCard from './BattleCard'
import { useGetBattlesQuery, useGetCharactersQuery } from '../../app/services/game';
import { useTheme, styled } from '@mui/material/styles';

export default function Battles() {

    const theme = useTheme()

    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
      }));

    
    const {
        data: battlesData,
        isLoading: battlesLoading,
        isError: battlesError,
        error: battlesErrorData
        } = useGetBattlesQuery();

    return (
    <Box 
        sx={{
        display:'flex', 
        flexDirection:'column'
        }}
    >
        <DrawerHeader/>
        <Typography paragraph  >
            Select a battle.
        </Typography>
        <Box
            sx={{
                display:'flex', 
                flexDirection:'row',
                flexWrap: 'wrap'
            }}
        >
            {battlesData && battlesData.map(battle => (
                <BattleCard
                    key={battle.id}
                    battle={battle}
                    battleId={battle.id}
                    battleStatus={battle.status}
                    battleCharacters={battle.battle_characters}
                    battleTurns={battle.battle_turns}
                    battleActiveChar={battle.active_character}
                />
            ))}
        </Box>
        <Box sx={{
        justifyContent: 'flex-end',
        bottom: 0,
        left: 'auto',
        right: 0,
        width: '100%',

        }}>
        </Box>
    </Box>
    );
    }
