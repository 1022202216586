import * as React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { useState } from 'react';
import { Ability, BattleCharacter } from '../../app/services/game';
import { updateNonNullChain } from 'typescript';


interface IProps {
  selectedAbility: Ability
  activeBattleCharacters: BattleCharacter[]
  selectedTargets: BattleCharacter[]
  setSelectedTargets: Function
}

const ITEM_HEIGHT = 72;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: '70%',
    },
  },
};

export default function TargetSelector(props:IProps) {


  const handleChange = (event: any) => {
    const value:number|number[] = event.target.value
    if (!value) {return}
    if (typeof value === 'number') {
      const targetObj:BattleCharacter|null = props.activeBattleCharacters.find((char)=>char.id === value) || null
      props.setSelectedTargets(targetObj?[targetObj]:[])
    } else {
      const matchedObjects: BattleCharacter[] = value
        .map(id => props.activeBattleCharacters.find(obj => obj.id === id))
        .filter((obj): obj is BattleCharacter => obj !== undefined);
      props.setSelectedTargets(matchedObjects)
    }
    //setTargetName(
      // On autofill we get a stringified value.
      //typeof value === 'string' ? value.split(',') : value,
    //);
    console.log(event)
  };

  return (
    <Box
      sx={{width: '80%'}}
    >
      <FormControl sx={{ m: 1, width:'100%' }}>
        <InputLabel id="demo-multiple-chip-label">{'Select Target(s)'}</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple={true}//{props.selectedAbility.multi_target?true:false}
          value={props.selectedTargets?.map((target) => target.id)}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Select Target(s)" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={props.activeBattleCharacters.find((char)=>char.id===value)?.character.name} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {props.activeBattleCharacters.map((char) => (
            <MenuItem
              key={char.id}
              value={char.id}
            >
              {char.character.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}