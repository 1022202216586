import * as React from 'react';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import { Battle, BattleCharacter, Character, User } from '../../app/services/game';
import { useGetCharactersQuery, useGetBattlesQuery } from '../../app/services/game';
import { selectUser, selectActiveBattle } from './gameSlice';
import { Avatar } from '@mui/material';
import { getActiveCharacter } from './BattleUtils'


const cardWidth = 325
const cardHeight = 75

interface IProps {
  battleChar: BattleCharacter
}


export default function BattleCharacterCard(props:IProps) {

  const {
    data: charactersData,
    isLoading: charactersLoading,
    isError: charactersError,
    error: charactersErrorData
  } = useGetCharactersQuery();

const {
  data: battlesData,
  isLoading: battlesLoading,
  isError: battlesError,
  error: battlesErrorData
} = useGetBattlesQuery();

  const currentBattleId: number | null = useSelector(selectActiveBattle)

  const currentBattle: Battle | null = battlesData?.find(battle =>
    battle.id === currentBattleId
  ) || null

  const loggedInUser = useSelector(selectUser);

  const activeCharacter:Character|null = currentBattle?getActiveCharacter(currentBattle):null

  const isActiveCard: boolean | null = activeCharacter ? activeCharacter.id === props.battleChar.character.id : null

  const cardBorderWidth: number = isActiveCard ? 3 : 0

  return (
    <Box display='flex' flexDirection='row' alignItems='center' sx={{ m:1 }}>
      <Card 
        variant="outlined"
        sx={{ 
            pt:.8,
            width: cardWidth,
            height: cardHeight,
            backgroundColor: (theme) => props.battleChar.team === 'Blue' ? theme.palette.primary.dark : theme.palette.secondary.dark,  // Use primary theme color
            border: cardBorderWidth || 0
          }}
      >
            <Box display='flex' flexDirection='row' alignItems='center'>
                <Avatar variant="square" sx={{m: 1.25}}> {props.battleChar.character.name.charAt(0).toUpperCase()} </Avatar>
                <Box display='flex' flexDirection='column' sx={{}}>
                    <Typography sx={{fontSize: 17}}> {props.battleChar.character.name} </Typography>
                    <Typography sx={{fontSize: 11}}> Ready </Typography>
                    <Typography sx={{fontSize: 11}}> 
                      HP: {props.battleChar.current_health}/
                          {props.battleChar.character.max_health} || AE: {props.battleChar.current_special} / 
                          {props.battleChar.character.max_special} || SP: {props.battleChar.current_speed}/{props.battleChar.character.max_speed}
                    </Typography>
                </Box>
            </Box>
      </Card>
    </Box>
  );
}