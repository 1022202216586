import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Link, Card, Button } from '@mui/material';
import { BattleCharacter, useGetBattlesQuery, useGetCharactersQuery } from '../../app/services/game';
import BattleCharacterCard from './BattleCharacterCard';
import { useDispatch, useSelector } from 'react-redux';
import { selectActiveBattle, setActiveScreen } from './gameSlice';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme, styled } from '@mui/material/styles';
import AbilityBar from './AbilityBar';
import { getCurrentTurn } from './BattleUtils';
import { PropaneSharp } from '@mui/icons-material';

export default function BattleDetail() {

    const theme = useTheme()

    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
      }));

    const dispatch = useDispatch();


    const {
        data: battlesData,
        isLoading: battlesLoading,
        isError: battlesError,
        error: battlesErrorData
    } = useGetBattlesQuery(undefined, {pollingInterval:3000});

    const activeBattleId = useSelector(selectActiveBattle);

    let activeBattle = null;
    if (activeBattleId !== null && battlesData) {
        activeBattle = battlesData.find(battle => battle.id === activeBattleId) || null;
    }



    const currentTurn = activeBattle?getCurrentTurn(activeBattle):null
    const slots = currentTurn && currentTurn.battle_action_slots
        ?currentTurn.battle_action_slots
        :null
    console.log(slots)

    const battleCharIds:number[] | null = slots
        ?slots.map((slot) => slot.battle_character)
        :activeBattle?.battle_characters.map(char => char.id)
        || null
    console.log(battleCharIds)
    
    const getOrderedBattleChars = (
        battleCharIds: number[],
        battle_chars: BattleCharacter[]
    ): BattleCharacter[] => {
        // Find battle_chars based on battleCharIds
        const matchedBattleChars = battleCharIds
          .map(id => battle_chars.find(battleChar => battleChar.id === id))
          .filter((char): char is BattleCharacter => char !== undefined); // Type guard to ensure char is not undefined
      
        // Sort matched battle_chars by current_speed in descending order

        return matchedBattleChars.sort(
            (a, b) => b.current_speed - a.current_speed
        );
    };

    const battleChars = battleCharIds && activeBattle 
        ? getOrderedBattleChars(battleCharIds, activeBattle.battle_characters)
        : null

    
    const handleBackClick = () => {
        dispatch(setActiveScreen({ screen: "Battles" }));
    };




    return (

        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                flexShrink:0,
                //minHeight: '85%', // Ensure the container fills the viewport
                height: '100%',
                overflowY:'hidden'

            }}
        >
            <DrawerHeader/>
            <Link
                onClick={handleBackClick}
                sx={{ m: 1 }}
            >
                {"<<< Back"}
            </Link>
            <Typography sx={{ml:1}} paragraph>
                Turn 14.2
            </Typography>
            <Box id='hello' sx={{flexGrow: 1,  width: '100%', overflowY:'auto' }}>

                <Box
                    sx={{
                        overflowY: 'auto',
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        padding: 1,
                        flexShrink:0
                    }}
                >
                    {battleChars?.map(char => (
                        <BattleCharacterCard
                            key={char.id}
                            battleChar={char}
                        />
                    ))}
                </Box>
            </Box>
            <Box sx={{ flexShrink:0, width: '100%', overflowY:'hidden'  }}>
                <Accordion
                    disableGutters={true}
                    defaultExpanded={true}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        Abilities
                    </AccordionSummary>
                    <AccordionDetails>
                        
                        <AbilityBar activeBattle={activeBattle}/>

                    </AccordionDetails>
                </Accordion>
                <Accordion
                    disableGutters={true}
                    defaultExpanded={true}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                    >
                        Messages
                    </AccordionSummary>
                    <AccordionDetails>
                        <Card>
                            <Typography variant='body2'>
                                14.1: VonDaxxos used MOLLYWOP and rolled 20. Critical Hit!!!
                            </Typography>
                            <Typography variant='body2'>
                                14.1: Harrowmancer's Health decreased by 10.
                            </Typography>
                            <Typography variant='body2'>
                                14.2: GassiusClay used HEAL and rolled 10.
                            </Typography>
                            <Typography variant='body2'>
                                14.2: GassiusClay's HEALTH increased by 5.
                            </Typography>
                        </Card>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </Box>
    );
}
