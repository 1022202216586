// battleUtils.ts

/*####################################################
These Battle Utility functions should all accept a
Battle object as their only argument and may return
values of any type.

-- jblum
####################################################*/


import { Battle, BattleTurn, BattleActionSlot, BattleCharacter, Character } from "../../app/services/game";
import BattleCard from "./BattleCard";

export const getCurrentTurn = (battle: Battle): BattleTurn => {
     let turns = battle.battle_turns;
     return turns.filter(turn => !turn.is_ended).sort((a, b) => a.no - b.no)[0];
}
  
export const getCurrentSlot = (battle: Battle ): BattleActionSlot | null => {
  let turn = getCurrentTurn(battle)
  return turn?.battle_action_slots.find(slot => slot.status === 'Active') ?? null;
}
  
export const getActiveBattleCharacter = (battle: Battle): BattleCharacter | null => {
  let slot:BattleActionSlot | null = getCurrentSlot(battle);
  let battleChars: BattleCharacter[] = battle.battle_characters;
  if (!slot || !battleChars) return null;
  const battleCharacter = battleChars.find(char => char.id === slot?.battle_character) ?? null;
  return battleCharacter
}

export const getActiveCharacter = (
  battle: Battle,
): Character | null => {
  const battleCharacter = getActiveBattleCharacter(battle)
  return battleCharacter?.character ?? null;
};