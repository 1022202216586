import { Box, Button, Select, Typography, FormControl, MenuItem, InputLabel, SelectChangeEvent } from '@mui/material';
import { Ability, ActionRequest, ActionResponse, Battle, BattleCharacter, 
    CommandRequest, CommandResponse } from '../../app/services/game';
import { useDispatch, useSelector } from 'react-redux';
import { selectActiveBattle, selectUser } from './gameSlice';
import { usePostActionMutation, usePostCommandMutation } from '../../app/services/game';
import { getActiveBattleCharacter, getCurrentSlot } from './BattleUtils';
import { useState } from 'react';
import { Action } from '@reduxjs/toolkit';
import TargetSelector from './TargetSelector';

interface IProps {
    activeBattle: Battle | null;
}

export default function AbilityBar(props: IProps) {
    const activeBattleId = useSelector(selectActiveBattle);
    const user = useSelector(selectUser);

    const [selectedAbility, setSelectedAbility] = useState<Ability | null>(null);
    const [selectedTargets, setSelectedTargets] = useState<BattleCharacter[]>([]);


    const [postCommand] = usePostCommandMutation();
    const [postAction] = usePostActionMutation();

    const dispatch = useDispatch();
  
    const handleAbilityClick = (ability:Ability) => {
        console.log("Handling Ability Click")
        setSelectedAbility(ability)
    }

    async function handleTargetSubmit(
        value: number,
        slot: number
    ) {
        console.log(value)
        let targettas = selectedTargets.map((target) => {
            let obj = {
                'battle_character': -1 // -1 as number placeholder
            }
            obj.battle_character = target.id
            return obj
        })
        let actionRequest:ActionRequest = {
            "battle_action_targets": targettas,
            "ability": value,
            "battle_action_slot": slot
        }

        try {
            const response:ActionResponse = await postAction(actionRequest).unwrap();
            setSelectedAbility(null)
            setSelectedTargets([])
        } catch (err) {
            console.log(actionRequest)
            console.log(err)
        }
    }


    async function handleCommandClick(value: string, battleChar: BattleCharacter) {
        console.log(value)
        let commandRequest:CommandRequest = {
            'command': value,
            'battle_character': battleChar.id
        }

        try {
            const response:CommandResponse = await postCommand(commandRequest).unwrap();
        } catch (err) {
            console.log(err)
        }
    }

    // Check conditions to render actions
    if (props.activeBattle && user) {
        const activeSlot = getCurrentSlot(props.activeBattle)
        console.log(activeSlot)
        const battleCharacters: BattleCharacter[] = props.activeBattle.battle_characters;
        const activeBattleCharacters: BattleCharacter[] = battleCharacters
            .filter((char) => char.can_battle)
        const battleChar: BattleCharacter | null = getActiveBattleCharacter(props.activeBattle)


        
        // Render the target selector after the user
        // selects an ability.
        if (selectedAbility && activeSlot) {
            return(
                <Box
                    display='flex'
                    flexDirection='row'
                    sx={{
                        width: '100%'
                    }}
                >
                    <Typography>
                        Use {selectedAbility.name.toUpperCase()} on:
                    </Typography>
                    <TargetSelector
                        selectedAbility={selectedAbility}
                        activeBattleCharacters={activeBattleCharacters}
                        selectedTargets={selectedTargets}
                        setSelectedTargets={setSelectedTargets}
                    />
                    <Button 
                        variant='contained' 
                        sx={{ m: 1, ml:2 }}
                        onClick={() => handleTargetSubmit(selectedAbility.id, activeSlot.id)}
                    > 
                        Submit 
                    </Button>

                    
                </Box>
            )
        }

        if (props.activeBattle.status === 'Matching') {

            const playerIsHost = props.activeBattle.created_by === user.id;
            
            if (playerIsHost) {
                return (
                    <Box>
                        <Button
                            variant='contained'
                            sx={{ m: 1 }}>Start
                        </Button>
                        <Button variant='contained' sx={{ m: 1 }}>Abandon</Button>
                    </Box>
                );
            } else {
                return (
                    <Box>
                        <Typography>No actions available.</Typography>
                    </Box>
                );
            }
            
        } else if (props.activeBattle.status === 'Active'&& battleChar) {
            const abilities: Ability[] = battleChar.character.abilities;

            // Only show the Surrender button if the user does not own
            // the active Battle Character.

            if(battleChar.character.user === null || user === null) {
                return (
                    <Box>
                        <Button
                            variant='outlined' 
                            color='error' 
                            sx={{ m: 1}}
                        >
                            Surrender
                        </Button>
                    </Box>
                )
            }
            if(battleChar.character.user.id !== user.id){
                console.log(battleChar.character.user)
                console.log(user)
                console.log('Do you want to surrender?')
                return (
                    <Box>
                        <Button
                            variant='outlined' 
                            color='error' 
                            sx={{ m: 1}}
                        >
                            Surrender
                        </Button>
                    </Box>
                )
            }

            return (
                <Box>
                    {abilities.map((ability, index) => (
                        <Button 
                            key={index}
                            variant='contained'
                            sx={{ m: 1 }}
                            onClick={() => handleAbilityClick(ability)}
                        >
                            {ability.name}
                        </Button>
                    ))}
                    <Button variant='outlined' color='error' sx={{ m: 1}}>Surrender</Button>
                </Box>
            );
        }
    }

    // Default rendering when conditions aren't met
    return (
        <Box>
            <Button variant='contained' sx={{ m: 1 }}>Heal</Button>
        </Box>
    );
}
