import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useLoginMutation, LoginRequest, UserResponse } from '../../app/services/game';
import { setCredentials } from './gameSlice';
import { useDispatch } from 'react-redux';

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function SignIn() {

  const [login] = useLoginMutation();

  const dispatch = useDispatch()

  const [formState, setFormState] = React.useState<LoginRequest>({
    username: '',
    password: ''
  })

  async function handleSubmit() {
    try {
        const user:UserResponse = await login(formState).unwrap();
        dispatch(setCredentials(user))
        //localStorage.setItem('authUser', user.token)
    } catch (err) {
        //notify(JSON.stringify(err))
        console.log(err)
    }
  }

  const handleFormChange = ({
    target: {name, value }
  }: React.ChangeEvent<HTMLInputElement>) =>
  setFormState((prev) => ({ ...prev, [name]: value}));


  return (
    <Container component="main" maxWidth="xs">
    <CssBaseline />
    <Box
        sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        }}
    >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
        Sign in
        </Typography>
        <TextField
            margin="normal"
            onChange={handleFormChange}
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoFocus
        />
        <TextField
            margin="normal"
            onChange={handleFormChange}
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
        />
        <Button
            type="submit"
            onClick={handleSubmit}
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
        >
            Sign In
        </Button>
        <Grid container>
            <Grid item xs>
            <Link href="#" variant="body2">
                Forgot password?
            </Link>
            </Grid>
            <Grid item>
            <Link href="#" variant="body2">
                {"Don't have an account? Sign Up"}
            </Link>
            </Grid>
        </Grid>
    </Box>
    <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}