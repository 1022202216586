import React from 'react';
import { useSelector } from 'react-redux';
import logo from './logo.svg';
import './App.css';
import Game from './features/game/Game';
import Login from './features/game/Login';
import { selectUser } from './features/game/gameSlice';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

// Define your Material-UI theme
const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#3f51b5',
    },
    secondary: {
      main: '#f50057',
    },
  },
  typography: {
    fontFamily: 'Monospace',
  },
});

function App() {
  // Selecting user from Redux store
  const user = useSelector(selectUser);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline /> {/* Resets some CSS styles and initializes Material-UI's theme */}
      {user ? <Game /> : <Login />}
    </ThemeProvider>
  );
}

export default App;
